const data = [
    {
        name: "God jul och gott nytt år",
        from: "Jocke",
        audioPath: require("./audio/jb.mp3"),
        formants: require("./formants/jb.json"),
    },
    {
        name: "God jul och gott nytt år",
        from: "Herman",
        audioPath: require("./audio/herman.mp3"),
        formants: require("./formants/herman.json"),
    },
    {
        name: "Hararei Koa",
        from: "Ann-Marie",
        audioPath: require("./audio/aj.mp3"),
        formants: require("./formants/aj.json"),
    },
    {
        name: "С новым годом",
        from: "Dasha",
        audioPath: require("./audio/dasha.mp3"),
        formants: require("./formants/dasha.json"),
    },
    {
        name: "Boldog Karácsonyt",
        from: "Amy",
        audioPath: require("./audio/amy.mp3"),
        formants: require("./formants/amy.json"),
    },
    {
        name: "Merry Christmas",
        from: "Harry",
        audioPath: require("./audio/harry.mp3"),
        formants: require("./formants/harry.json"),
    },
    {
        name: "Merry Christmas",
        from: "David",
        audioPath: require("./audio/david.mp3"),
        formants: require("./formants/david.json"),
    },
    {
        name: "新年快乐",
        from: "Jack",
        audioPath: require("./audio/jack.mp3"),
        formants: require("./formants/jack.json"),
    },
    {
        name: "聖誕節快樂",
        from: "Andrea",
        audioPath: require("./audio/andrea.mp3"),
        formants: require("./formants/andrea.json"),
    },
    {
        name: "Frohe Weihnachten",
        from: "Martin",
        audioPath: require("./audio/martin.mp3"),
        formants: require("./formants/martin.json"),
    },
    {
        name: "Feliz navidad",
        from: "Natalia",
        audioPath: require("./audio/natalia.mp3"),
        formants: require("./formants/natalia.json"),
    },
];

let usedIndices = [];

export const triangledData = [1, 1, 2, 3, 4].map(count => {
    let row = [];
    for (let i = 0; i < count; i++) {
        let index;
        do {
            index = Math.floor(Math.random() * data.length);
        } while (usedIndices.includes(index));
        usedIndices.push(index);
        row.push(data[index]);
    }
    return row;
});
