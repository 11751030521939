import * as THREE from "./lib/three.module";
import { getExpressiveness, getFormantAverage } from "./analyse-formants";
import {
    LOW_COLOR,
    HIGH_COLOR,
    PEAK_COLOR,
    PEAK_LIMIT,
    SPHERE_RADIUS,
    NUM_SEGMENTS,
    HEIGHT_MULT,
    IDLE_LEVEL,
    IDLE_PEAK,
} from "./variables";
import Oscillator from "./oscillator"; 

const FORMANT_POSITIONS = [
    new THREE.Vector2(-SPHERE_RADIUS, -SPHERE_RADIUS),
    new THREE.Vector2(SPHERE_RADIUS, -SPHERE_RADIUS),
    new THREE.Vector2(SPHERE_RADIUS, SPHERE_RADIUS),
    new THREE.Vector2(-SPHERE_RADIUS, SPHERE_RADIUS),
];

export default class Wave {
    constructor(scene, name, audioPath, colors = {
        lowColor: LOW_COLOR,
        highColor: HIGH_COLOR,
        peakColor: PEAK_COLOR,
        peakLimit: PEAK_LIMIT
    }) {
        this.name = name;
        this.audioPath = audioPath;
        this.position = new THREE.Vector3();
        this.scene = scene;
        this.colors = colors;
        this.hovered = false;
    }

    load(formantSamples) {
        const formantAverages = getFormantAverage(formantSamples);
        this.expressiveness = getExpressiveness(formantSamples);

        this.peakSize = (this.expressiveness / 10) * HEIGHT_MULT;
        this.direction = Math.random() > 0.5 ? 1 : -1;

        const geometry = new THREE.SphereBufferGeometry(
            SPHERE_RADIUS,
            NUM_SEGMENTS,
            NUM_SEGMENTS
        );

        this.heightAttribute = new Float32Array(
            geometry.attributes.position.count
        );

        geometry.addAttribute(
            "height",
            new THREE.BufferAttribute(this.heightAttribute, 1)
        );

        const material = new THREE.ShaderMaterial({
            uniforms: {
                peakSize: { value: this.peakSize },
                lowColor: { value: this.colors.lowColor },
                highColor: { value: this.colors.highColor },
                peakColor: { value: this.colors.peakColor },
                peakLimit: { value: this.colors.peakLimit },
            },
            defaultAttributeValues: {
                height: 0.0,
            },
            side: THREE.DoubleSide,
            opacity: true,
            vertexShader: document.getElementById("vertexShader").textContent,
            fragmentShader: document.getElementById("fragmentShader")
                .textContent,
        });
        this.mesh = new THREE.Mesh(geometry, material);
        this.mesh.wave = this;
        this.mesh.position.copy(this.position);

        const frequencyMultiplier = this.expressiveness / 10;

        this.oscillators = formantAverages.map((formantFrequency, fIndex) => {
            const gap =
                fIndex === 0
                    ? formantFrequency
                    : formantFrequency - formantAverages[fIndex - 1];
            const osc = new Oscillator(
                FORMANT_POSITIONS[fIndex],
                (formantFrequency / 1000) * frequencyMultiplier
            );
            osc.speed = gap / 100;
            return osc;
        });

        this.idleOscillator = new Oscillator(new THREE.Vector2(0, 0), 3);
        this.idleOscillator.speed = 100;

        var audioLoader = new THREE.AudioLoader();
        audioLoader.load(this.audioPath, buffer => {
            this.audioBuffer = buffer;
            this.sound = new THREE.Audio(this.scene.listener);
            this.sound.setBuffer(this.audioBuffer);
            this.sound.setVolume(0.5);
            const handleClipEnd = this.handleClipEnd.bind(this);
            this.sound.onEnded = function() {
                this.isPlaying = false;
                handleClipEnd();
            };
    
            // create an AudioAnalyser, passing in the sound and desired fftSize
            this.audioAnalyser = new THREE.AudioAnalyser(this.sound, 32);

            this.scene.addWave(this);
        });
    }

    playClip() {
        if (!this.sound) return;
        if (this.sound.isPlaying) {
            this.sound.stop();
        }
        this.sound.play();
    }

    handleClipEnd() {
        if (this.onClipEnd) {
            this.onClipEnd();
        }
    }

    update(now) {
        this.oscillators.forEach(osc => osc.update(now));
        this.idleOscillator.update(now);

        let envelope = 0;
        if (this.sound.isPlaying) {
            const data = this.audioAnalyser.getFrequencyData().slice(1, 5);
            const sum = data.reduce((acc, n) => acc + n) / 255 / data.length;

            // NOTE: these multipliers are _incorrect_.
            // We overdrive it so we get some hot colours
            // should be * 3, and a maximum of 1.0.
            envelope = Math.min(Math.max(sum - 0.45, 0) * 10, 100.0);
        } else if (this.hovered) {
            envelope = 1;
        } else {
            // Idle
            envelope = IDLE_LEVEL;
        }

        const stride = 3;
        const verts = this.mesh.geometry.attributes.position.array;
        for (
            var vertexStart = 0;
            vertexStart < verts.length;
            vertexStart += stride
        ) {
            let normalized;
            const vertexIndex = vertexStart / stride;
            const x = verts[vertexStart + 0];
            const y = verts[vertexStart + 1];
            const z = verts[vertexStart + 2];
            const vertexPosition = new THREE.Vector2(x, y);
            if (this.sound.isPlaying || this.hovered) {
                const oscillatorSum = this.oscillators.reduce((acc, osc) => {
                    return acc + osc.getValueAtPosition(vertexPosition);
                }, 0);
                normalized = oscillatorSum / this.oscillators.length;
            } else {
                normalized = this.idleOscillator.getValueAtPosition(vertexPosition);
            }
            this.heightAttribute[vertexIndex] = normalized * envelope;
        }

        this.mesh.material.uniforms.peakLimit.value = this.sound.isPlaying ? this.colors.peakLimit : IDLE_PEAK;
        this.mesh.material.uniforms.needsUpdate = true;

        this.mesh.rotation.y =
            (this.direction * (now * this.expressiveness)) / 80;
        this.mesh.rotation.z =
            (this.direction * (now * this.expressiveness)) / 80;

        this.mesh.geometry.computeVertexNormals();
        this.mesh.geometry.computeFaceNormals();
        this.mesh.geometry.verticesNeedUpdate = true;
        this.mesh.geometry.colorsNeedUpdate = true;
        this.mesh.geometry.normalsNeedUpdate = true;
        this.mesh.geometry.attributes.height.needsUpdate = true;
    }
}
