import React from "react";
import "./footer.css";
import logo from "./logo.svg";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <a href="https://smallmultiples.com.au"><img src={logo} alt="Small Multiples Logo"/></a>
                <span>
                    Creative Direction: Jack Zhao | UX / UI Design: Dasha
                    Yurovskaya | Creative Development: Harry Morris | Voices:
                    Small Multiples Team
                </span>
            </footer>
        );
    }
}

export default Footer;
