import React, { Component } from "react";
import WaveComponent from "./wave-component";
import ReactGA from 'react-ga';
import { triangledData } from "./data";
import Footer from "./footer";
import "./App.css";
import {
    STAR_LOW_COLOR,
    STAR_HIGH_COLOR,
    STAR_PEAK_COLOR,
    STAR_PEAK_LIMIT,
} from "./variables";

const getKey = datum => datum.from;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: null,
            hovered: null,
        };
    }

    componentDidMount() {
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-25119617-13');
        ReactGA.pageview('/xmas2018');
    }

    getClickHandler(datum) {
        return () =>
            this.setState({
                playing: datum ? getKey(datum) : null,
            });
    }
    getFinishHandler(datum) {
        return () => {
            if (datum && getKey(datum) === this.state.playing) {
                this.setState({
                    playing: null,
                });
            }
        };
    }
    getMouseOverHandler(datum) {
        return () =>
            this.setState({
                hovered: datum ? getKey(datum) : null,
            });
    }
    getMouseOutHandler(datum) {
        return () => {
            if (datum && getKey(datum) === this.state.hovered) {
                this.setState({
                    hovered: null,
                });
            }
        };
    }

    renderGridItem(datum, star) {
        const { playing, hovered } = this.state;

        const key = getKey(datum);
        const itemHovered = hovered && hovered === key;
        const itemPlaying = playing && playing === key;
        const tooltipVisible = itemHovered;
        const colors = star
            ? {
                  lowColor: STAR_LOW_COLOR,
                  highColor: STAR_HIGH_COLOR,
                  peakColor: STAR_PEAK_COLOR,
                  peakLimit: STAR_PEAK_LIMIT,
              }
            : undefined;
        return (
            <div className="gridItem" key={key}>
                <WaveComponent
                    {...datum}
                    onClick={this.getClickHandler(datum)}
                    onMouseOver={this.getMouseOverHandler(datum)}
                    onMouseOut={this.getMouseOutHandler(datum)}
                    play={itemPlaying}
                    hovered={itemHovered}
                    tooltipVisible={tooltipVisible}
                    onFinish={this.getFinishHandler(datum)}
                    colors={colors}
                    star={star}
                />
            </div>
        );
    }

    renderGridItems() {
        return triangledData.map((row, index) => {
            const star = index === 0;
            const rowItems = row.map(datum => this.renderGridItem(datum, star));
            return (
                <div className="gridRow" key={index} data-faded={!this.state.gestured}>
                    {rowItems}
                </div>
            );
        });
    }

    renderHeading() {
        return (
            <h1 className="header">
                <span className="headerSmall">The shape of</span>
                <span className="headerBig">Holiday Greetings</span>
                <span className="headerSmall">
                    from the team at{" "}
                    <a className="smuLink" href="https://smallmultiples.com.au">
                        Small Multiples
                    </a>
                </span>
                <p className="headerPara">
                    Hover over each bauble to see the greetings from each of our team, and click them to hear it.
                </p>
            </h1>
        );
    }

    render() {
        return (
            <>
                <div className="container">
                    {this.renderHeading()}
                    <div className="grid">
                        {this.renderGridItems()}
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default App;
