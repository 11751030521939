import * as THREE from "./lib/three.module";

export const CLEAR_COLOR = new THREE.Color("#8199F5");
export const LOW_COLOR = new THREE.Color("#FFE019");
export const HIGH_COLOR = new THREE.Color("#91ECCF");
export const PEAK_COLOR = new THREE.Color("#8199F5");
export const PEAK_LIMIT = 0.6;
export const IDLE_PEAK = 0.5;
export const STAR_LOW_COLOR = new THREE.Color("#FF00FF");
export const STAR_HIGH_COLOR = new THREE.Color("#91ECCF");
export const STAR_PEAK_COLOR = new THREE.Color("#FF00FF");
export const STAR_PEAK_LIMIT = 0.1;

// Shape
export const SPHERE_RADIUS = 120;
export const NUM_SEGMENTS = 64;
export const HEIGHT_MULT = 7;
export const IDLE_LEVEL = 0.3;


// Audio analysis
export const EXPRESSIVENESS_WINDOW = 5;
export const HYSTERESIS_PROPORTION = 0.5;