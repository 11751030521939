import * as THREE from "./lib/three.module";
import {
    CLEAR_COLOR
} from "./variables";

export default class WaveScene {
    constructor(canvasElement) {
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({ canvas: canvasElement });

        const canvasSize = canvasElement.getBoundingClientRect();

        this.width = canvasSize.width;
        this.height = canvasSize.height;

        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(CLEAR_COLOR);

        this.camera = new THREE.PerspectiveCamera(
            70,
            this.width / this.height,
            1,
            1000
        );
        this.camera.position.y = -200;
        this.camera.position.z = -200;
        this.camera.lookAt(new THREE.Vector3(0, 0, 0));

        this.listener = new THREE.AudioListener();
        this.camera.add(this.listener);

        this.wave = null;
        this.update = this.update.bind(this);
    }

    addWave(wave) {
        this.wave = wave;
        this.scene.add(wave.mesh);
    }

    start() {
        window.requestAnimationFrame(this.update);
    }

    update() {
        if (this.wave) {
            const now = performance.now() / 1000;
            this.wave.update(now);
        }
        this.draw();
    }

    draw() {
        this.renderer.render(this.scene, this.camera);
        window.requestAnimationFrame(this.update);
    }
}