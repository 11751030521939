import React from "react";
import WaveNode from "./wave-node";
import WaveScene from "./wave-scene";

import "./wave-component.css";

export default class WaveComponent extends React.Component {
    componentDidMount() {
        this.setupScene();
    }

    componentDidUpdate(prevProps) {
        if (this.props.play && !prevProps.play) {
            this.wave.playClip();
        }

        if (this.props.hovered !== prevProps.hovered) {
            this.wave.hovered = this.props.hovered;
        }
    }

    handleClipEnd() {
        this.props.onFinish();
    }

    setupScene() {
        const { name, audioPath, formants, colors } = this.props;
        this.scene = new WaveScene(this.canvas);
        this.wave = new WaveNode(this.scene, name, audioPath, colors);
        this.wave.load(formants);
        this.wave.onClipEnd = this.handleClipEnd.bind(this);
        this.scene.start();
    }

    renderTooltip() {
        const { tooltipVisible, name, from } = this.props;
        return (
            <div className="waveTooltip" data-visible={tooltipVisible}>
                <h2 className="waveTooltipText">{name}</h2>
                <h3 className="waveTooltipLanguage">{from}</h3>
            </div>
        );
    }

    render() {
        return (
            <div
                className="waveComponent"
                onClick={this.props.onClick}
                onMouseOver={this.props.onMouseOver}
                onMouseOut={this.props.onMouseOut}
                data-star={this.props.star}
            >
                <canvas ref={r => (this.canvas = r)} />
                {this.renderTooltip()}
            </div>
        );
    }
}
