import {
    EXPRESSIVENESS_WINDOW,
    HYSTERESIS_PROPORTION,
} from "./variables";

export function getExpressiveness(formantSamples) {
    const formantSequences = Object.keys(formantSamples[0])
        .filter(k => k !== "time")
        .map(d => formantSamples.map(s => s[d]));
        
    let triggerCount = 0;
    formantSequences.forEach((formantSequence, fIndex) => {
        let over = false;
        let under = false;
        formantSequence.forEach((fValue, sampleIndex) => {
            const windowSample = formantSequence.slice(
                Math.max(0, sampleIndex - EXPRESSIVENESS_WINDOW),
                sampleIndex
            );
            const windowAverage =
                windowSample.reduce((acc, n) => acc + n, 0) /
                windowSample.length;
    
            const windowHighTrigger = windowAverage * (1 + HYSTERESIS_PROPORTION);
            const windowHigh = windowAverage * (1 + HYSTERESIS_PROPORTION / 2);
            const windowLow = windowAverage * (1 - HYSTERESIS_PROPORTION / 2);
            const windowLowTrigger = windowAverage * (1 - HYSTERESIS_PROPORTION);
    
            if (fValue > windowHighTrigger && !over) {
                triggerCount++;
                over = true;
            } else if (over && fValue < windowHigh) {
                over = false;
            } else if (fValue < windowLowTrigger && !under) {
                triggerCount++;
                under = true;
            } else if (under && fValue > windowLow) {
                under = false;
            }
        });
        
    });
    const length = formantSamples[formantSamples.length - 1].time;
    return triggerCount / length;
}

export function getFormantAverage(formantSamples) {
    let sums = [];
    let counts = [];
    formantSamples.forEach(sample => {
        Object.keys(sample)
            .filter(k => k !== "time")
            .forEach((fKey, fIndex) => {
                const val = sample[fKey];
                if (isNaN(val)) return;
                sums[fIndex] = (sums[fIndex] || 0) + val;
                counts[fIndex] = (counts[fIndex] || 0) + 1;
            });
    });

    const averages = sums.map((sum, index) => {
        return sum / counts[index];
    });

    return averages;
}
