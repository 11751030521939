export default class Oscillator {
    constructor(pos, frequency) {
        this.decayRate = 0;
        this.position = pos;
        this.time = 0;
        this.energy = 1;
        this.frequency = frequency;
        this.speed = 10;
        this.cycles = 3;
    }

    update(time) {
        this.time = time;
        this.energy *= 1 - this.decayRate;
    }

    getValueAtPosition(otherPosition) {
        const dist = this.position.distanceTo(otherPosition);
        const timeDifference = dist / this.speed;
        const timeAtPosition = Math.abs(this.time - timeDifference);

        const timeDivFrequency = timeAtPosition / this.frequency;
        const distanceDropoff = dist / (this.cycles * this.speed);

        const saw = ((timeDivFrequency % 1) - 0.5) * 2;
        const triangle = (Math.abs(saw) - 0.5) * 2;
        const sin = Math.sin(timeDivFrequency * (Math.PI * 2));

        return sin * this.energy; // * distanceDropoff;
    }
}